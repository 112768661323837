import React from 'react';
import Navbar from '../inc/Navbar';

import AddCareer from '../AddCareer';
import ScrollToTop from "react-scroll-to-top";



function Career(props) {
    return (
      
<>
<Navbar/>
<br/>
<br/>
<br/>
<br/>

<ScrollToTop smooth top="100" color="light" />

<AddCareer/>
</>
        
        );
    }
    
    export default Career;