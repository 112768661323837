import React from 'react'
import Header from '../inc/Navbar'
import AddDistributor from '../AddDistributor';
function Distributorpage() {
  return (
    <div>
        <Header/>
        <br/>
        <br/>
        <br/>
        
     

        <div className="p-3 mb-2 bg-light text-white">
        <br/>
        <br/>
                            <AddDistributor/>  
                            </div>  

    </div>
  )
}

export default Distributorpage