

import React, { useState, useEffect } from 'react';
import { useNavigate} from 'react-router-dom';
import { collection, getDocs, } from "firebase/firestore";

// import PaginationOld from '../pages/PaginationOld';

import {db} from '../../firebase'
import Navbar from '../inc/Navbar';
import {Link} from "react-router-dom";

import ScrollToTop from "react-scroll-to-top";
import "./Students.css";




function Students () {
    const [ setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
  
      const [showPerPage, setShowPerPage] = useState(9);
      const [pagination, setPagination] =useState({
          start: 0,
          end: showPerPage,
      });
  
      const onPaginationChange = (start,  end) =>{
          setPagination({start: start, end: end});
      };
      const [searchTerm, setsearchTerm] = useState("");
      const [students, setStudents] = useState([])
      const navigate = useNavigate()
  
      const studentsCollectionRef = collection(db, "students");
      useEffect(() => {
  
          const getStudents = async () => {
              const data = await getDocs(studentsCollectionRef);
              setStudents(data.docs.map((doc) => ({...doc.data(), id: doc.id })))
          }
  
          getStudents()
      }, [])
    return (
      
<>

<Navbar/>

<br/>
<ScrollToTop smooth top="100" color="maroon" />

<br/>
<br/>
<br/>

<div className="colors">
<div className="p-3 mb-2 bg-light text-dark">
   
<div className="album py-2">
        
            <div className="container">

                <div className="row">
                    
                    <h1><b> STUDENTS IN OUR <b style={{color:"grey"}}>CODING</b> CLASS</b></h1>
                    <br/>
                    </div>
                    </div>
                    </div>
                    </div>
                    </div>
             

<div className="album py-2">
        
            <div className="container">

                <div className="row">
               
                <div className="col-md-6">
                        
                        <div className="card-body">
                        <input type="text" className="form-control" placeholder="Search" name="location" 
                        onChange ={(e) => {
                          setsearchTerm(e.target.value);
                        }}
                        />
                                <div className="d-flex justify-content-between align-items-center">
                                   
                                    
                            

                            </div>
                          
                    
                        </div>

                      
                    </div>
             
                    </div>
                  
                    </div>
                    </div>
<div className="album py-2">
        
        <div className="container">

            <div className="row">
            {students.filter((val)=>{
      if (setsearchTerm === ""){
        return val;
      } else if (
        val.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        val.class.toLowerCase().includes(searchTerm.toLowerCase()) ||
        val.school.toLowerCase().includes(searchTerm.toLowerCase())
      
       
        
      ){
        return val;
      }
    }).slice(pagination.start, pagination.end).map((mrindiancoder) => { return (
            <div className="col-md-4 mt-3">
                        
                            <div className="studnetsreadius">
                        <img  className="card-img-top mb-2" src={mrindiancoder.img}  alt={mrindiancoder.name}/>
                        </div>
                            <div className="card-body">
                                <center>
                                <h3 style={{color:"purple"}} className="card-text"> <b>{mrindiancoder.name}</b></h3>
                               
                                <p style={{color:"gray"}} className="card-text mb-2"> {mrindiancoder.join}</p>

                                <h6 style={{color:"Darkblue"}} className="card-text">{mrindiancoder.class}</h6>
                                
                                </center>
                                <div className="d-flex justify-content-between align-items-center">
                              
                               
                              
                            </div>
                            </div>
                            </div>
                               
                          
                                )})} 
                            </div>
                            {/* <PaginationOld showPerPage={showPerPage} 
                onPaginationChange={onPaginationChange}
                total={students.length}
                /> */}


                            </div>
                            </div>
                            <br/>

</>
        
        );
    }
    
    export default Students;



