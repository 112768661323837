import React from 'react';

import './App.css';
import {BrowserRouter as Router, Routes, Route} from 'react-router-dom';

import Home from "./components/pages/Home";
import Contact from "./components/pages/Contact";
import About from "./components/pages/About";
import Coding from "./components/pages/Coding";
import Applications from "./components/pages/Applications";

import Student from "./components/pages/Student";
// import Uploadfile from "./components/pages/Uploadfile";

import Webbook from './components/pages/Webbook';
import Footer from './components/inc/Footer';

import Searchwebsite from './components/pages/Searchweb';

import Career from './components/pages/Career';
import Distributor from './components/pages/Distributor';
import Distributorpage from './components/pages/Distributorpage';
import Ourdevelop from './components/pages/Ourdevelop';




function App() {
  return (
    <Router>

      <div>

       
        
        <Routes>

        <Route axact path="/" element={<Home/>}/>
        <Route axact path="/Contact" element={<Contact/>}/>
        <Route axact path="/about" element={<About/>}/>
        <Route axact path="/coding" element={<Coding/>}/>
        <Route axact path="/application" element={<Applications/>}/>
     
        <Route axact path="/Student" element={<Student/>}/>
        {/* <Route axact path="/Uploadfiles" element={<Uploadfile/>}/> */}
       
        <Route axact path="/website-book" element={<Webbook/>}/>

        <Route axact path="/searchwebsite" element={<Searchwebsite/>}/>

        <Route axact path="/Career" element={<Career/>}/>
        <Route axact path="/distributor" element={<Distributor/>}/>
        <Route axact path="/Distributor-apply" element={<Distributorpage/>}/>
        <Route axact path="/our-development" element={<Ourdevelop/>}/>
       

        </Routes>
    
  <Footer/>
    </div>
   
    </Router>
  );
}

export default App;