

import React, { useState, useEffect } from 'react';

import Navbar from '../inc/Navbar';

import ScrollToTop from "react-scroll-to-top";



import { collection, getDocs, } from "firebase/firestore";
import { db } from '../../firebase'


function LabTest() {

  const [showPerPage, setShowPerPage] = useState(8);
  const [pagination, setPagination] = useState({
    start: 0,
    end: showPerPage,
  });

  const onPaginationChange = (start, end) => {
    setPagination({ start: start, end: end });
  };

  const [searchTerm, setsearchTerm] = useState("");
  const [coderbook, setCoderbook] = useState([])
  const coderbookRef = collection(db, "coderbook")





  useEffect(() => {
    const getcoderbook = async () => {
      const data = await getDocs(coderbookRef);
      setCoderbook(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })))
    }
    getcoderbook()
  }, [])






  return (
    <div >


      <Navbar />


      <br />
      <br />
      <br />
      <ScrollToTop smooth top="100" color="blue" />

      <div style={{
        backgroundImage: "url(" + " https://i.postimg.cc/zBLy9jrB/abstract-simple-colors-8k-ipad-air.jpg" + ")",
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat'
      }}>
        <br />
        <div className="album py-2">

          <div className="container">
            <br />
            <div className="row">
              <h1 style={{ color: "white", fontSize: "3rem" }} > <b>WEBSITE DEVELOPMENT</b> </h1>
              <h1 style={{ color: "white", fontSize: "2rem" }} > <b>Plan </b> </h1>
              <p style={{ color: "white" }} className="card-text mb-4">We Provide Best Service - India's Most Love Framework - React Js</p>

              <div className="album py-4">

                <div className="container">

                  <div className="row">
                    <div className="col-md-4 ">

                      <div className="card-body">
                        <input type="text" class="form-control" placeholder="Search " name="location"
                          onChange={(e) => {
                            setsearchTerm(e.target.value);
                          }}
                        />
                        <div className="d-flex justify-content-between align-items-center">




                        </div>


                      </div>


                    </div>


                  </div>
                </div>
              </div>
              <div className="album py-1">

                <div className="container">

                  <div className="row">
                    <div className="col-md-11">

                      <div className="card-body">


                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="album py-1">

                <div className="container">

                  <div class="row">
                    {coderbook.filter((val) => {
                      if (setsearchTerm === "") {
                        return val;
                      } else if (
                        val.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
                        val.detail.toLowerCase().includes(searchTerm.toLowerCase())

                      ) {
                        return val;
                      }
                    }).slice(pagination.start, pagination.end).map((mrindiancoder) => {
                      return (
                        <div className="col-md-4" >

                          <div className="card  mb-2 border-dark box shadow ">
                            <img src={mrindiancoder.webImg} alt="" />
                            <div className="card-body">
                              <center>
                                <h5 style={{ color: "Darkblue" }} className="card-text mb-3"><b>{mrindiancoder.name}</b></h5></center>


                              <p style={{ color: "Darkblue" }} className="card-text"><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-check-circle" viewBox="0 0 16 16">
                                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                                <path d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z" />
                              </svg> {mrindiancoder.language}</p>
                              <p style={{ color: "Darkblue" }} className="card-text"><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-check-circle" viewBox="0 0 16 16">
                                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                                <path d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z" />
                              </svg> {mrindiancoder.detail}</p>
                              <p style={{ color: "Darkblue" }} className="card-text"><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-check-circle" viewBox="0 0 16 16">
                                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                                <path d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z" />
                              </svg> {mrindiancoder.dynamic}</p>
                              <p style={{ color: "Darkblue" }} className="card-text"><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-check-circle" viewBox="0 0 16 16">
                                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                                <path d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z" />
                              </svg> {mrindiancoder.admin}</p>
                              <p style={{ color: "Darkblue" }} className="card-text"><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-check-circle" viewBox="0 0 16 16">
                                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                                <path d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z" />
                              </svg> {mrindiancoder.map}</p>
                              <p style={{ color: "Darkblue" }} className="card-text"><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-check-circle" viewBox="0 0 16 16">
                                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                                <path d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z" />
                              </svg> {mrindiancoder.domain}</p>
                              <p style={{ color: "Darkblue" }} className="card-text"><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-check-circle" viewBox="0 0 16 16">
                                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                                <path d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z" />
                              </svg> {mrindiancoder.hosting}</p>
                              <p style={{ color: "Darkblue" }} className="card-text"><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-check-circle" viewBox="0 0 16 16">
                                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                                <path d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z" />
                              </svg> {mrindiancoder.service}</p>
                              <p style={{ color: "Darkblue" }} className="card-text"><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-check-circle" viewBox="0 0 16 16">
                                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                                <path d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z" />
                              </svg> {mrindiancoder.upload}</p>
                              <p style={{ color: "Darkblue" }} className="card-text"><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-check-circle" viewBox="0 0 16 16">
                                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                                <path d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z" />
                              </svg> {mrindiancoder.ssl}</p>
                              <p style={{ color: "Darkblue" }} className="card-text"><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-check-circle" viewBox="0 0 16 16">
                                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                                <path d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z" />
                              </svg> {mrindiancoder.res}</p>
                              <p style={{ color: "Darkblue" }} className="card-text"><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-check-circle" viewBox="0 0 16 16">
                                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                                <path d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z" />
                              </svg> {mrindiancoder.call}</p>
                              <p style={{ color: "Darkblue" }} className="card-text"><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-check-circle" viewBox="0 0 16 16">
                                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                                <path d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z" />
                              </svg> {mrindiancoder.whatsapp}</p>

                              <div className="d-flex justify-content-between align-items-center">

                              </div>
                            </div>
                          </div>
                        </div>

                      )
                    })}

                  </div>

                </div>

              </div>

            </div>
          </div>
        </div>
      </div>


      <br />

      <marquee> <h6>Welcome MIC India's Most Iconic Website Developer Mr. Indian Coder </h6>
      </marquee>

      <br></br>
      <br></br>














      <hr />

      <br></br>
      <h2 style={{ color: "Darkblue" }}> <center>Thousands of happy clients</center></h2>
      <div id="carouselExampleDark" class="carousel carousel-dark slide" data-bs-ride="carousel">
        <div class="carousel-indicators">
          <button type="button" data-bs-target="#carouselExampleDark" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
          <button type="button" data-bs-target="#carouselExampleDark" data-bs-slide-to="1" aria-label="Slide 2"></button>
          <button type="button" data-bs-target="#carouselExampleDark" data-bs-slide-to="2" aria-label="Slide 3"></button>
        </div>
        <div class="carousel-inner">
          <div class="carousel-item active" data-bs-interval="10000">
            <div className="px-4 py-5 my-12 text-center">
              <h5>Mr. Indian Coder has responsibly created our website</h5>
              <p className="btn btn btn-lg px-4 gap-3">Dr. Soumen Chatterjee (MBBS)</p>
            </div>

          </div>
          <div className="carousel-item" data-bs-interval="2000">
            <div className="px-4 py-5 my-12 text-center">
              <h5>Mr Indian Coder has made our website even better than our dreams</h5>
              <p className="btn btn btn-lg px-4 gap-3">Sanmukh Sharma (Social Worker)</p>
            </div>

          </div>
          <div class="carousel-item">
            <div className="px-4 py-5 my-12 text-center">
              <h5>Mr Indian Coder has made our website very beautiful</h5>
              <p className="btn btn btn-lg px-4 gap-3">Arindam Biswas (Entrepreneur)</p>
            </div>

          </div>
        </div>
        <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleDark" data-bs-slide="prev">
          <span class="carousel-control-prev-icon" aria-hidden="true"></span>
          <span class="visually-hidden">Previous</span>
        </button>
        <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleDark" data-bs-slide="next">
          <span class="carousel-control-next-icon" aria-hidden="true"></span>
          <span class="visually-hidden">Next</span>
        </button>
      </div>





      <div class="p-2 mb-2 bg-dark text-light">
        <div className="album py-3">
          <div className="container">
            <div className="row">
              <div className="col-md-4 mt-8" >
                <h2 className="card-text"> <b><center>1250+</center></b></h2>
                <p className="card-text"><center> Complete Project</center></p>
                <div className="d-flex justify-content-between align-items-center">
                </div>
              </div>
              <div className="col-md-4 mt-6" >
                <h2 className="card-text"><b><center>1150+</center></b></h2>
                <p className="card-text"><center>Happy Clients</center></p>
                <div className="d-flex justify-content-between align-items-center">
                </div>

              </div>

              <div className="col-md-4" >



                <h2 style={{ textAlign: "center" }} className="card-text"><b><center>12+</center></b></h2>
                <p style={{ textAlign: "center" }} className="card-text"> Our Product</p>
                <div className="d-flex justify-content-between align-items-center">




                </div>

              </div>
            </div>
          </div>

        </div>
      </div>

    </div>







  );
}

export default LabTest;