import React from 'react'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Navbar from "../inc/Navbar";
import Mbdharandsonjewellers from "../images/MBDHAR.jpg";
import Rupaecobrick from "../images/rupaecobrick.jpg";
import Manmohini from "../images/manmohibi.jpg";

import Bimanbastralaya from "../images/bimanbastralaya.jpg";
import Umahardware from "../images/umahardware.jpg";
import Biswasfurniture from "../images/biswasfurniture.jpg";
import Sreekrishnajewellers from "../images/sreekrishnajewellers.jpg";
import Clasiktilesandmarbel from "../images/CLASIKTILES.jpg";
import Sarkarmedical from "../images/sarkarmedicalcentre.jpg";

import Psluxuryhotel from "../images/psluxuryhotel.jpg";
import theskillfoundation from "../images/theskillfoundation.jpg";
import Shoppingbazaar from "../images/shoppingbazaar.jpg";
import Sunitjewellers from "../images/sunitijewellers.jpg";
import Abchomoeocare from "../images/abchomoeocare.jpg";
import hotelsangam from "../images/HOTELSANGAM.jpg";
import Evantraders from "../images/evantraders.jpg";
import Paulshotelkrishnanagar from "../images/paulshotelkrishnagar.jpg";

import Drsankarkumarroy from "../images/drsankarkumarroy.jpg";

import bholebabatourism from "../images/BHOLEBABA TOURISHM KARIMPUR.jpg";
import prothamakrishnanagar from "../images/PROTHAMA - KRISHNAGAR.jpg";
import questdiagnosticskrishnanagar from "../images/QUEST DIAGNOSTICS KRISHNANAGAR.jpg";
import rannabatikrishnagar from "../images/RANNABATI KRISHNAGAR.jpg";
import basashreeinnkrishnagar from "../images/basashree inn Krishnagar.jpg";
import sghjewellerskrishnanagar from "../images/SGH Jewellers Krishnanagar.jpg";
import Magicberhampore from "../images/magic berhampore.jpg";
import Jagatbandhutti from "../images/Jagatbandhu tti.jpg";
import Maajewellersdhubulia from "../images/MAA JEWELLERS.jpg";
import draftsmanshipartacademy from "../images/draftsman-logo.jpg";
import apmcfinance from "../images/apmc-finance berhampore.jpg";
import misaaberhampore from "../images/MISAA-LOGO.jpg";
import centralsericulture from "../images/Ministry of Textiles, Govt. of India.jpg";
import aajadhyakothihillresort from "../images/Aajadhya Kothi Hill Resort.jpg";
import Krishnagarprabhatsangha from "../images/KRISHNAGAR PRABHAT SANGHA.jpg"
import desertmina from "../images/desertmina.jpg";


import "./Develop.css";


function Silck() {

        const settings = {
                dots: true,
                infinite: true,
                speed: 500,
                slidesToShow: 3,
                slidesToScroll: 3
        };

        return (
                <div>
                        <Navbar />
                        <div className='develop'>
                                <center><h2 style={{ color: "Darkblue" }} className='mb-0'><b>OUR DEVELOP WEBSITE</b></h2></center>
                        </div>
                        <div className="animat">
                                <Slider {...settings}>

                                        <div>

                                                <div className="card mb-4 border-White box ">
                                                        <a href='https://www.mbdharandsonjewellers.com/' target='_blank' rel="noopener noreferrer">
                                                                <img src={Mbdharandsonjewellers} className="card-img-top" alt="Mbdharandsonjewellers" />
                                                        </a>

                                                </div>

                                        </div>

                                        <div>
                                                <div className="card mb-4 border-White box ">
                                                        <a href='https://www.rupaecobrick.com/' target='_blank' rel="noopener noreferrer">
                                                                <img src={Rupaecobrick} className="card-img-top" alt="Rupaecobrick" />
                                                        </a>

                                                </div>
                                        </div>

                                        <div>
                                                <div className="card mb-4 border-White box ">
                                                        <a href='https://www.manmohini.com/' target='_blank' rel="noopener noreferrer">
                                                                <img src={Manmohini} className="card-img-top" alt="Manmohini" />
                                                        </a>

                                                </div>
                                        </div>

                                        <div>
                                                <div className="card mb-4 border-White box ">
                                                        <a href='https://bimanbastralaya.com/' target='_blank' rel="noopener noreferrer">
                                                                <img src={Bimanbastralaya} className="card-img-top" alt="Bimanbastralaya" />
                                                        </a>

                                                </div>
                                        </div>

                                        <div>
                                                <div className="card mb-4 border-White box ">
                                                        <a href='https://www.umahardware.in/' target='_blank' rel="noopener noreferrer">

                                                                <img src={Umahardware} className="card-img-top" alt="Umahardware" />
                                                        </a>

                                                </div>
                                        </div>


                                        <div className="card mb-4 border-White box ">
                                                <a href=' https://www.biswasfurniture.com/' target='_blank' rel="noopener noreferrer">
                                                        <img src={Biswasfurniture} className="card-img-top" alt="{Biswasfurniture" />
                                                </a>

                                        </div>


                                        <div className="card mb-4 border-White box ">
                                                <a href='https://www.mssreekrishnajewellers.com/' target='_blank' rel="noopener noreferrer">
                                                        <img src={Sreekrishnajewellers} className="card-img-top" alt="Chain" />
                                                </a>

                                        </div>
                                        <div className="card mb-4 border-White box ">
                                                <a href='https://www.clasiktilesandmarbel.com/' target='_blank' rel="noopener noreferrer">
                                                        <img src={Clasiktilesandmarbel} className="card-img-top" alt="Clasiktilesandmarbel" />
                                                </a>

                                        </div>


                                        <div>
                                                <div className="card mb-4 border-White box ">
                                                        <a href='https://www.sarkarmedical.in/' target='_blank' rel="noopener noreferrer">
                                                                <img src={Sarkarmedical} className="card-img-top" alt="Kanbala" />
                                                        </a>

                                                </div>

                                        </div>
                                        <div className="card mb-4 border-White box ">
                                                <a href='https://psluxuryhotel.com/' target='_blank' rel="noopener noreferrer">
                                                        <img src={Psluxuryhotel} className="card-img-top" alt="Psluxuryhotel" />
                                                </a>

                                        </div>
                                        <div className="card mb-4 border-White box ">
                                                <a href='https://www.skillfoundation.org/' target='_blank' rel="noopener noreferrer">

                                                        <img src={theskillfoundation} className="card-img-top" alt="theskillfoundation" />
                                                </a>

                                        </div>

                                        <div className="card mb-4 border-White box ">
                                                <a href='https://www.shoppingbazaar.co.in/' target='_blank' rel="noopener noreferrer">
                                                        <img src={Shoppingbazaar} className="card-img-top" alt="Shoppingbazaar" />
                                                </a>

                                        </div>
                                        <div className="card mb-4 border-White box ">
                                                <a href='https://www.sunitijewellers.com/' target='_blank' rel="noopener noreferrer">
                                                        <img src={Sunitjewellers} className="card-img-top" alt="Sunitjewellers" />
                                                </a>

                                        </div>
                                        <div className="card mb-4 border-White box ">
                                                <a href='https://www.abchomoeocare.in/' target='_blank' rel="noopener noreferrer">
                                                        <img src={Abchomoeocare} className="card-img-top" alt="Abchomoeocare" />
                                                </a>

                                        </div>
                                        <div className="card mb-4 border-White box">
                                                <a href='https://www.hotelsangams.com/' target='_blank' rel="noopener noreferrer">
                                                        <img src={hotelsangam} className="card-img-top" alt="hotelsangam" />
                                                </a>

                                        </div>


                                        <div className="card mb-4 border-White box ">
                                                <a href='https://www.paulshotel.com/' target='_blank' rel="noopener noreferrer">
                                                        <img src={Paulshotelkrishnanagar} className="card-img-top" alt="Paulshotelkrishnanagar" />
                                                </a>
                                        </div>

                                        <div className="card mb-4 border-White box ">
                                                <a href='https://www.drsankarkumarroy.com/' target='_blank' rel="noopener noreferrer">
                                                        <img src={Drsankarkumarroy} className="card-img-top" alt="Drsankarkumarroy" />
                                                </a>
                                        </div>

                                        <div className="card mb-4 border-White box ">
                                                <a href='https://www.bholebabatourism.com/' target='_blank' rel="noopener noreferrer">
                                                        <img src={bholebabatourism} className="card-img-top" alt="bholebabatourism" />
                                                </a>
                                        </div>
                                        <div className="card mb-4 border-White box ">
                                                <a href='https://www.prothama.com/' target='_blank' rel="noopener noreferrer">
                                                        <img src={prothamakrishnanagar} className="card-img-top" alt="prothamakrishnanagar" />
                                                </a>
                                        </div>
                                        <div className="card mb-4 border-White box ">
                                                <a href='https://www.questdiagnostics.org.in/' target='_blank' rel="noopener noreferrer">
                                                        <img src={questdiagnosticskrishnanagar} className="card-img-top" alt="questdiagnosticskrishnanagar" />
                                                </a>
                                        </div>
                                        <div className="card mb-4 border-White box ">
                                                <a href='https://www.rannabatihsgroup.com/' target='_blank' rel="noopener noreferrer">
                                                        <img src={rannabatikrishnagar} className="card-img-top" alt="rannabatikrishnagar" />
                                                </a>
                                        </div>
                                        <div className="card mb-4 border-White box ">
                                                <a href='https://www.basashreeinn.com/' target='_blank' rel="noopener noreferrer">
                                                        <img src={basashreeinnkrishnagar} className="card-img-top" alt="basashreeinnkrishnagar" />
                                                </a>
                                        </div>
                                        <div className="card mb-4 border-White box ">
                                                <a href='https://www.sghjewellers.com/' target='_blank' rel="noopener noreferrer">
                                                        <img src={sghjewellerskrishnanagar} className="card-img-top" alt="sghjewellerskrishnanagar" />
                                                </a>
                                        </div>
                                        <div className="card mb-4 border-White box ">
                                                <a href='https://www.magicwb.com/' target='_blank' rel="noopener noreferrer">
                                                        <img src={Magicberhampore} className="card-img-top" alt="Magicberhampore" />
                                                </a>
                                        </div>
                                        <div className="card mb-4 border-White box ">
                                                <a href='https://www.jagatbandhutti.org.in/' target='_blank' rel="noopener noreferrer">
                                                        <img src={Jagatbandhutti} className="card-img-top" alt="jagatbandhutti" />
                                                </a>
                                        </div>
                                        <div className="card mb-4 border-White box ">
                                                <a href='https://www.maajewellersdhubulia.com/' target='_blank' rel="noopener noreferrer">
                                                        <img src={Maajewellersdhubulia} className="card-img-top" alt="Maajewellersdhubulia" />
                                                </a>
                                        </div>
                                        <div className="card mb-4 border-White box ">
                                                <a href='https://www.draftsmanship.in/' target='_blank' rel="noopener noreferrer">
                                                        <img src={draftsmanshipartacademy} className="card-img-top" alt="draftsmanshipartacademy"/>
                                                </a>
                                        </div>
                                        <div className="card mb-4 border-White box ">
                                                <a href='https://www.apmcfinance.in/' target='_blank' rel="noopener noreferrer">
                                                        <img src={apmcfinance} className="card-img-top" alt="apmcfinance"/>                                                </a>
                                        </div>
                                        <div className="card mb-4 border-White box ">
                                                <a href='https://www.csrtiber.res.in/' target='_blank' rel="noopener noreferrer">
                                                        <img src={centralsericulture} className="card-img-top" alt="centralsericulture"/>
                                                </a>
                                        </div>
                                        <div className="card mb-4 border-White box ">
                                                <a href='https://www.misaa.org.in/' target='_blank' rel="noopener noreferrer">
                                                        <img src={misaaberhampore} className="card-img-top" alt="misaaberhampore"/>
                                                </a>
                                        </div>

                                        
                                        <div className="card mb-4 border-White box ">
                                                <a href='https://www.aajadhyakothihillresort.com/' target='_blank' rel="noopener noreferrer">
                                                        <img src={aajadhyakothihillresort} className="card-img-top" alt="aajadhyakothihillresort"/>
                                                </a>
                                        </div>

                                        
                                        <div className="card mb-4 border-White box ">
                                                <a href='https://www.krishnagarprabhatsangha.org/' target='_blank' rel="noopener noreferrer">
                                                        <img src={Krishnagarprabhatsangha} className="card-img-top" alt="Krishnagarprabhatsangha"/>
                                                </a>
                                        </div>
                                        <div className="card mb-4 border-White box ">
                                                <a href='https://www.desertmina.com/' target='_blank' rel="noopener noreferrer">
                                                        <img src={desertmina} className="card-img-top" alt="desertmina"/>
                                                </a>
                                        </div>
                                        <div>



                                        </div>


                                </Slider>
                        </div>

                </div>
        )
}



export default Silck