

import { initializeApp } from "firebase/app";
import { getAuth } from 'firebase/auth';
import "firebase/firestore";
import { getFirestore } from "firebase/firestore";
import { getStorage } from 'firebase/storage'
import "firebase/storage";



const firebaseConfig = {
    apiKey: "AIzaSyDvCvQtKRPRr95b05BqdOdsU1zX6UyWafw",
    authDomain: "mr-indian-coder.firebaseapp.com",
    projectId: "mr-indian-coder",
    storageBucket: "mr-indian-coder.appspot.com",
    messagingSenderId: "1052191159203",
    appId: "1:1052191159203:web:80720b15d43c175b1e6016",
    measurementId: "G-81N5SJM0L7"
 
};
//init firebase app
const app = initializeApp(firebaseConfig);
export const storage = getStorage(app);
export const auth = getAuth(app);
export const db = getFirestore(app);




export default app;