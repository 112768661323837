import React from "react";
import Navbar from "../inc/Navbar";
import AddApplication from "../AddApplication"
import {Link } from "react-router-dom";
function Applications () {
    return(
            <>
            <Navbar/>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <div className="album py-2">
           <div className="container">

<div className="row">
    
           <nav aria-label="breadcrumb">
  <ol className="breadcrumb">
    <li className="breadcrumb-item"><Link to="/"> Home</Link ></li>
    <li className="breadcrumb-item"><Link to="/coding" >Coding Course Launch</Link></li>
    <li className="breadcrumb-item active" aria-current="page">Apply for Coding Course</li>
  </ol>
</nav>
</div> 
</div>
 </div> 



            <AddApplication/>
            
            </>
    );
}

export default Applications;