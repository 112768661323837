import React, { useState } from "react";
import { Form, Alert, InputGroup, Button, ButtonGroup, Card } from "react-bootstrap";
import BookDataCareer from "../services/book.application";

import '../App.css';
const AddCareer = () => {
    const [title, setTitle] = useState(""); 
    const [lastqualification, setLastqualification] = useState(""); 
    const [city, setCity] = useState(""); 
    const [school, setSchool] = useState(""); 
    const [mobile, setMobile] = useState(""); 
    const [emailaddress, setEmailaddress ] = useState ("");
    const [status, setStatus] = useState("Available");
    const [flag, setFlag] = useState(true);
    const [massage, setMassage] = useState({ error: false, msg: ""});



   const handleSubmit = async  (e) => {
       e.preventDefault();
       setMassage("");
       if(title === "" || mobile === "" || emailaddress === "" || school === "" || city ==="" || lastqualification === "") {
           setMassage({error: true, msg : "All fields are mandatory!"});
           return;
       }
       const newBook = {
           title,
           mobile,
           emailaddress,
           school,
           city,
           lastqualification,
           status
       }
       console.log(newBook);

       try {
           await BookDataCareer.addBooks(newBook);
           setMassage({ error: false, msg : "Application submited success!"});
       } catch (err) {
           setMassage({ error: true, msg: err.massage });
       }

       setTitle("");
       setMobile("");
       setEmailaddress("");
       setSchool("");
       setCity("");
       setLastqualification("");
   };
    return (
        <>
           
           <div className="album py-2">
           <div className="container">

<div className="row justify-content-center">
        <div className="col-md-7">
           <Card body >
               <br/>
               <div className="fullstack">
                            <center><h5 style={{textAlign: "center"}}>APPLY FOR FULL STACK WEBSITE DEVELOPMENT</h5></center>   
                            </div>

              
               <br/>
         <div className="card mb-4 box">
        {massage?.msg && (<Alert variant={massage?.error ? "danger": "success"}
         dismissible 
         onClose={() => setMassage("")}
         >
             {""}
             {massage?.msg}
         </Alert>
         )}
   
            <Form onSubmit={handleSubmit} >
                <Form.Group className="mb-3" controlId="formBookTitle">
                    <InputGroup>
                    <InputGroup.Text id="formBookLocation">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-person-circle" viewBox="0 0 16 16">
  <path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"/>
  <path fill-rule="evenodd" d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1z"/>
</svg>
                    </InputGroup.Text>
                    <Form.Control type="text"
                     placeholder="Enter your name"
                     value={ title}
                     onChange={(e) => setTitle(e.target.value)}
                     />
                    </InputGroup>
                </Form.Group>

               
                <Form.Group className="mb-3" controlId="formBookSchool">
                <InputGroup>
                    <InputGroup.Text id="formBookLocation">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-buildings" viewBox="0 0 16 16">
  <path d="M14.763.075A.5.5 0 0 1 15 .5v15a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5V14h-1v1.5a.5.5 0 0 1-.5.5h-9a.5.5 0 0 1-.5-.5V10a.5.5 0 0 1 .342-.474L6 7.64V4.5a.5.5 0 0 1 .276-.447l8-4a.5.5 0 0 1 .487.022ZM6 8.694 1 10.36V15h5V8.694ZM7 15h2v-1.5a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 .5.5V15h2V1.309l-7 3.5V15Z"/>
  <path d="M2 11h1v1H2v-1Zm2 0h1v1H4v-1Zm-2 2h1v1H2v-1Zm2 0h1v1H4v-1Zm4-4h1v1H8V9Zm2 0h1v1h-1V9Zm-2 2h1v1H8v-1Zm2 0h1v1h-1v-1Zm2-2h1v1h-1V9Zm0 2h1v1h-1v-1ZM8 7h1v1H8V7Zm2 0h1v1h-1V7Zm2 0h1v1h-1V7ZM8 5h1v1H8V5Zm2 0h1v1h-1V5Zm2 0h1v1h-1V5Zm0-2h1v1h-1V3Z"/>
</svg>
</InputGroup.Text>
            
                    <Form.Control type="text"
                     placeholder="Full Name School / College / University"
                     value={ school}
                     onChange={(e) => setSchool(e.target.value)}
                     />
                   </InputGroup>
                </Form.Group>

                <Form.Group className="mb-3" controlId="formBookLastqualification">
                <InputGroup>
                    <InputGroup.Text id="formBookLocation">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-left-square" viewBox="0 0 16 16">
  <path d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z"/>
  <path d="M10.205 12.456A.5.5 0 0 0 10.5 12V4a.5.5 0 0 0-.832-.374l-4.5 4a.5.5 0 0 0 0 .748l4.5 4a.5.5 0 0 0 .537.082z"/>
</svg>
</InputGroup.Text>
                    <Form.Control type="text"
                     placeholder="Last Qualification"
                     value={ lastqualification}
                     onChange={(e) => setLastqualification(e.target.value)}
                     />
                     
                     </InputGroup>
                </Form.Group>

                <Form.Group className="mb-3" controlId="formBookMobile">
                <InputGroup>
                    <InputGroup.Text id="formBookLocation">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-telephone-plus-fill" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M1.885.511a1.745 1.745 0 0 1 2.61.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511zM12.5 1a.5.5 0 0 1 .5.5V3h1.5a.5.5 0 0 1 0 1H13v1.5a.5.5 0 0 1-1 0V4h-1.5a.5.5 0 0 1 0-1H12V1.5a.5.5 0 0 1 .5-.5z"/>
</svg>
</InputGroup.Text>
                    <Form.Control type="text"
                    placeholder="Contact number"
                    value={ mobile}
                    onChange={(e) => setMobile(e.target.value)}
                    />
                   </InputGroup>
                </Form.Group>

                <Form.Group className="mb-3" controlId="formBookEmailaddress">
                <InputGroup>
                    <InputGroup.Text id="formBookLocation">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-envelope-paper-fill" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M6.5 9.5 3 7.5v-6A1.5 1.5 0 0 1 4.5 0h7A1.5 1.5 0 0 1 13 1.5v6l-3.5 2L8 8.75l-1.5.75ZM1.059 3.635 2 3.133v3.753L0 5.713V5.4a2 2 0 0 1 1.059-1.765ZM16 5.713l-2 1.173V3.133l.941.502A2 2 0 0 1 16 5.4v.313Zm0 1.16-5.693 3.337L16 13.372v-6.5Zm-8 3.199 7.941 4.412A2 2 0 0 1 14 16H2a2 2 0 0 1-1.941-1.516L8 10.072Zm-8 3.3 5.693-3.162L0 6.873v6.5Z"/>
</svg>
</InputGroup.Text>

                    <Form.Control type="text"
                     placeholder="Email address"
                     value={ emailaddress}
                     onChange={(e) => setEmailaddress(e.target.value)}
                     />
                 </InputGroup>
                </Form.Group>
                

             

                <Form.Group className="mb-3" controlId="formBookCity">
                <InputGroup>
                    <InputGroup.Text id="formBookLocation">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-geo-alt-fill" viewBox="0 0 16 16">
  <path d="M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10zm0-7a3 3 0 1 1 0-6 3 3 0 0 1 0 6z"/>
</svg>
</InputGroup.Text>
                    <Form.Control type="text"
                    
                     placeholder="Location"
                     value={ city}
                     onChange={(e) => setCity(e.target.value)}
                     />
                   </InputGroup>
                </Form.Group>


                


                
        
                {/* <ButtonGroup aria-lable="Basic example" className="mb-3">
                    <Button 
                    disabled={flag} variant="success" 
                    onClick={(e) => {
                    setStatus("Available");
                    setFlag(true);
                    }}
                    >
                        Available
                        </Button>
                        <Button variant="danger" 
                        onClick={(e) => {
                        setStatus("Not Available");
                        setFlag(false);
                        }}
                        >Not Available</Button>
        
                </ButtonGroup> */}

                        <div className="d-grid gap-2">
                            <Button variant="primary" type="Submit">
                           Submit
                            </Button>
                        </div>
                

            </Form>
          
        </div>
        </Card>
        </div>
        </div>
        </div>
        </div>
    
      
        </>


    )
}
export default AddCareer;